<template>
  <div class="display-flex alig-items-center hidden-extra-content">
    <v-sheet
      v-for="(item, index) in 5"
      :key="index"
      color="grey lighten-4 mr-5"
      class="pa-1"
    >
      <v-skeleton-loader
        width="270px"
        height="140px"
        class="mx-auto"
        type="article"
      ></v-skeleton-loader>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "HomeFeaturedSupplierSkeletonLayout",
};
</script>

<style scoped>
.hidden-extra-content {
  width: 100%;
  overflow: hidden;
}
</style>